<template>
  <ModalMain @close="close()">
    <div class="px-4 md:px-7 pt-4 w-full">
      <article class="animate-fade-in">
        <div class="text-center block">
          <h1 class="text-3xl tracking-widest mt-2 text-center animate-slide-down-fade-in01s">Sign In</h1>
          <div class="text-slate-100 mt-4 mb-6 animate-slide-down-fade-in04s text-center">
            <p>
              Almost there! As a final step your <span class="text-white font-bold">{{ name }}</span> wallet application will ask you
              to digitally sign the request to link it with MetaWin.
            </p>
          </div>
          <ButtonButton
            type="button"
            class="block w-full mt-8"
            :is-loading="signaturePending"
            :disabled="signaturePending"
            @click.prevent.stop="signIn()"
          >
            Sign In
          </ButtonButton>
        </div>
      </article>
    </div>
  </ModalMain>
</template>

<script>
import { mapWritableState, mapState, mapActions } from 'pinia';
import { useUiStore } from '@/store/ui';
import { useAuthStore } from '@/store/auth';
import { useWalletProviderStore } from '@/store/wallet-provider';

export default defineComponent({
  name: 'ModalSignatureMain',
  computed: {
    ...mapWritableState(useUiStore, ['showSignatureModal',]),
    ...mapWritableState(useAuthStore, ['signaturePending', 'isLoginPending', 'errorMessage',]),
    ...mapState(useAuthStore, ['firstLogin',]),
    ...mapState(useAuthStore, ['wallet',]),
    name() {
      return this.wallet?.peerName ?? this.wallet.name;
    },
  },
  mounted() {
    this.$gtmCustomEvent({ event: 'modal_show', action: 'modalOpen', modalName: 'Signature', });
  },
  methods: {
    ...mapActions(useAuthStore, ['loginUser', 'logout',]),
    ...mapActions(useWalletProviderStore, ['disconnect',]),
    async signIn() {
      this.signaturePending = true;
      await this.loginUser();
    },
    async close() {
      if (this.firstLogin) {
        await this.logout();
        const router = useRouter();
        return router.push('/');
      }

      await this.disconnect();
      this.errorMessage = 'The sign in request was rejected.';
      this.isLoginPending = false;
      this.showSignatureModal = false;
    },
  },
});
</script>
